import { _Client } from '@/api-client';
import { ExaminerRoleEnum, GenderEnum, GetAllCityRep, GetAllRegionRep, GetByIdEmExaminerModel, GetByIdEmmExaminerModel, ModifyExaminerCmd, ExaminerMonitorStatusEnum, BusinessAttachmentTypeEnum, WorkStatusEnum, AttachmentResponse, GetAllProvinceRep, GetProvinceCityRep, ExaminerProfileStatusEnum, ExaminerLastSessionStatusEnum, ExaminerCertificationStatusEnum, SystemStatusEnum } from '@/api-client/client';
import { defineComponent, reactive, unref, onMounted, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { Modal, message } from 'ant-design-vue';
import type { UploadProps } from 'ant-design-vue';
import moment from 'moment'
import FooterBar from '@/components/FooterBar/index.vue'
import { LeftOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { Form } from 'ant-design-vue';
import dayjs from 'dayjs';

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: Response;
  url: string;
}
const useForm = Form.useForm;
export default defineComponent({
  components: { FooterBar, LeftOutlined, PlusOutlined },
  setup() {
    const timeMoment = moment
    const { currentRoute, push, back } = useRouter();
    const route = unref(currentRoute);
    const examinerId: string = route.params.examinerId.toString();
    const examinerRoleEnum = ExaminerRoleEnum;
    const examinerRoleEnums = [ExaminerRoleEnum.Speaking, ExaminerRoleEnum.VCS, ExaminerRoleEnum.SecondMarking, ExaminerRoleEnum.Writing, ExaminerRoleEnum.PSN, ExaminerRoleEnum.EORSPK, ExaminerRoleEnum.EORWRT];
    const workStatuss = [WorkStatusEnum.FTE, WorkStatusEnum.B2B, WorkStatusEnum.SAE, WorkStatusEnum.STE, WorkStatusEnum.GMH, WorkStatusEnum.TL, WorkStatusEnum.DNEM, WorkStatusEnum.Others, WorkStatusEnum.PartTime, WorkStatusEnum.DummyExaminer]
    const genderEnums = [GenderEnum.Female, GenderEnum.Male]
    const genderEnum = GenderEnum
    const workStatusEnum = WorkStatusEnum
    const examinerProfileStatusEnum = ExaminerProfileStatusEnum
    const examinerMonitorStatusEnum = ExaminerMonitorStatusEnum
    const examinerLastSessionStatusEnum = ExaminerLastSessionStatusEnum
    const examinerCertificationStatusEnum = ExaminerCertificationStatusEnum
    const examinerSystemStatusEnum = SystemStatusEnum
    const examinerRoles = [
      { label: 'Speaking', value: 'Speaking', disabled: false },
      { label: 'VCS', value: 'VCS', disabled: false },
      { label: 'ICS', value: 'ICS', disabled: false },
      { label: 'Writing', value: 'Writing', disabled: false },
      { label: '2nd Marking', value: '2nd Marking', disabled: false },
      { label: 'PSN', value: 'PSN', disabled: false },
      { label: 'EOR SPK', value: 'EORSPK', disabled: false }
    ]

    const examinerRoleChecked = [];
    const state = reactive<{
      examinerDetailForm: GetByIdEmExaminerModel;
      layout: string,
      emExaminerDetail: GetByIdEmExaminerModel,
      emmExaminerDetail: GetByIdEmmExaminerModel,
      regionList: GetAllRegionRep[],
      photoFileType: string,
      passportCopyFileType: string,
      cityList: GetAllCityRep[],
      provinceList: GetAllProvinceRep[],
      rulesRef: { [key: string]: any },
      examinerSpkProfileStatuss: ExaminerProfileStatusEnum[],
      examinerWrtProfileStatuss: ExaminerProfileStatusEnum[],
      examinerMonitorStatuss: ExaminerMonitorStatusEnum[],
      examinerLastSessionStatuss: ExaminerLastSessionStatusEnum[],
      examinerCertificationStatuss: ExaminerCertificationStatusEnum[]
      examinerDetailPageTitle: string,
      examinerRoleChecked: string[],
      provinceCityList: GetProvinceCityRep[]
    }>
      ({
        examinerDetailForm: new GetByIdEmExaminerModel(<GetByIdEmExaminerModel>{ firstName: undefined, lastName: undefined, initial: undefined, nameonPassport: undefined, gender: undefined, no: undefined, mobile: undefined, emailAddress: undefined, nationality: undefined, regionId: undefined, cityBasedId: undefined, emergencyContactEmail: undefined, emergencyContactMobileNumber: undefined, middleName: undefined, emergencyContactName: undefined, ukvi: undefined }),
        layout: 'inline',
        emExaminerDetail: new GetByIdEmExaminerModel(),
        emmExaminerDetail: new GetByIdEmmExaminerModel(),
        regionList: new Array<GetAllRegionRep>(),
        photoFileType: '',
        passportCopyFileType: '',
        cityList: new Array<GetAllCityRep>(),
        provinceList: new Array<GetAllProvinceRep>(),

        rulesRef: {
          firstName: [
            { required: true, message: "Please input first name" },
          ],
          lastName: [
            { required: true, message: "Please input last name" },
          ],
          initial: [
            { required: true, message: "Please input initial" },
          ],
          nameonPassport: [
            { required: true, message: "Please input name on passport" },
          ],
          no: [
            { required: true, message: "Please input examiner no" },
          ],
          emailAddress: [
            { required: true, message: "Please input email address" },
          ],
          nationality: [
            { required: true, message: "Please input nationality" },
          ],
          regionId: [
            { required: true, message: "Please input region" },
          ],
          cityBasedId: [
            { required: true, message: "Please input city based" },
          ],
          workStatus: [
            { required: true, message: "Please input work type" },
          ],
          gender: [
            { required: true, message: "Please input gender" },
          ],
          mobile: [
            { required: true, message: "Please input mobile" },
          ],
          passPortNumber: [
            {
              pattern: /^[A-Z0-9]+$/,
              message: "Passport Number format is wrong"
            }
          ],
          emergencyContactEmail: [
            {
              required: false,
              type: 'email',
              message: "Emergency Contact Email format is wrong."
            }
          ],
          emergencyContactMobileNumber: [
            {
              required: false,
              pattern: /^1[3456789]\d{9}$/,
              message: "Emergency Contact Mobile Number format is wrong."
            }
          ],
          middleName: [
            {
              required: false,
              pattern: /^[a-zA-Z\s]+$/,
              message: "Middle Name format is wrong."
            }
          ],
          emergencyContactName: [
            {
              required: false,
              pattern: /^[a-zA-Z\s]+$/,
              message: "Emergency Contact Name format is wrong."
            }
          ],
          ukvi: [
            {
              required: true
            }
          ]
        },
        examinerSpkProfileStatuss: [ExaminerProfileStatusEnum.Withdraw],
        examinerWrtProfileStatuss: [ExaminerProfileStatusEnum.NA, ExaminerProfileStatusEnum.New, ExaminerProfileStatusEnum.Active, ExaminerProfileStatusEnum.Suspend, ExaminerProfileStatusEnum.Left, ExaminerProfileStatusEnum.Withdraw],
        examinerMonitorStatuss: [ExaminerMonitorStatusEnum.NA, ExaminerMonitorStatusEnum.Active, ExaminerMonitorStatusEnum.Active6, ExaminerMonitorStatusEnum.Suspend],
        examinerLastSessionStatuss: [ExaminerLastSessionStatusEnum.NA, ExaminerLastSessionStatusEnum.Active, ExaminerLastSessionStatusEnum.InactiveL1, ExaminerLastSessionStatusEnum.InactiveL1Activated, ExaminerLastSessionStatusEnum.InactiveL2, ExaminerLastSessionStatusEnum.InactiveL2Activated, ExaminerLastSessionStatusEnum.InactiveL3],
        examinerCertificationStatuss: [ExaminerCertificationStatusEnum.NA, ExaminerCertificationStatusEnum.Active, ExaminerCertificationStatusEnum.Expired, ExaminerCertificationStatusEnum.ReCertifying],

        examinerDetailPageTitle: '',
        examinerRoleChecked: new Array<string>(),
        provinceCityList: new Array<GetProvinceCityRep>()
      });

    const uploadPhotoRef = ref(null)
    const uploadPassportRef = ref(null)
    const saveExamienrDetailLoading = ref<boolean>(true)
    const size = ref(0)
    const confirmUpdatesBtnShow = ref<boolean>(false);
    const confirmUpdateBtnLoading = ref<boolean>(false);
    const confirmUpdatesOrNot = ref<boolean>(false);
    const uploadPhotoLoading = ref<boolean>(false)
    const uploadPassportLoading = ref<boolean>(false)
    const leftEffectiveDayjsDate = ref(null);
    const isDisabledEditProfileStatus = ref<boolean>(false);

    const { resetFields, validate, validateInfos } = useForm(state.examinerDetailForm, state.rulesRef);

    async function getExaminerDetail() {
      try {
        saveExamienrDetailLoading.value = true;
        let examinerDetail = await _Client.examinerClient.getById(examinerId)
        state.emExaminerDetail = examinerDetail.emExaminer!;
        if (examinerDetail.emmExaminer) {
          state.emmExaminerDetail = examinerDetail.emmExaminer!;
          state.emmExaminerDetail.birthDate = <Date><unknown>moment(state.emmExaminerDetail.birthDate)
          state.emmExaminerDetail.old_BirthDate = <Date><unknown>moment(state.emmExaminerDetail.old_BirthDate)

          if (state.emExaminerDetail.speakingProfileStatus === ExaminerProfileStatusEnum.Withdraw) {
            isDisabledEditProfileStatus.value = true;
          }

        }
        state.emExaminerDetail.visaDueDate = (state.emExaminerDetail.visaDueDate === null || state.emExaminerDetail.visaDueDate === undefined) ? undefined
          : <Date><unknown>moment(state.emExaminerDetail.visaDueDate)
        state.emExaminerDetail.entranceDate = (state.emExaminerDetail.entranceDate === null || state.emExaminerDetail.entranceDate === undefined) ? undefined
          : <Date><unknown>moment(state.emExaminerDetail.entranceDate)
        state.emExaminerDetail.leftEffectiveDate = (state.emExaminerDetail.leftEffectiveDate === null || state.emExaminerDetail.leftEffectiveDate === undefined) ? undefined
          : <Date><unknown>moment(state.emExaminerDetail.leftEffectiveDate)
        if (state.emExaminerDetail.leftEffectiveDate) {
          leftEffectiveDayjsDate.value = dayjs(state.emExaminerDetail.leftEffectiveDate);
        }

        state.examinerDetailForm.firstName = state.emExaminerDetail.firstName;
        state.examinerDetailForm.lastName = state.emExaminerDetail.lastName;
        state.examinerDetailForm.initial = state.emExaminerDetail.initial;
        state.examinerDetailForm.nameonPassport = state.emExaminerDetail.nameonPassport;
        state.examinerDetailForm.gender = state.emExaminerDetail.gender;
        state.examinerDetailForm.no = state.emExaminerDetail.no;
        state.examinerDetailForm.mobile = state.emExaminerDetail.mobile;
        state.examinerDetailForm.telephoneNumber = state.emExaminerDetail.telephoneNumber;
        state.examinerDetailForm.emailAddress = state.emExaminerDetail.emailAddress;
        state.examinerDetailForm.nationality = state.emExaminerDetail.nationality;
        state.examinerDetailForm.regionId = state.emExaminerDetail.regionId;
        state.examinerDetailForm.workStatus = state.emExaminerDetail.workStatus;
        state.examinerDetailForm.cityBasedId = state.emExaminerDetail.cityBasedId;
        state.examinerDetailForm.passPortNumber = state.emExaminerDetail.passPortNumber;
        state.examinerDetailForm.emergencyContactEmail = state.emExaminerDetail.emergencyContactEmail;
        state.examinerDetailForm.emergencyContactMobileNumber = state.emExaminerDetail.emergencyContactMobileNumber;
        state.examinerDetailForm.middleName = state.emExaminerDetail.middleName;
        state.examinerDetailForm.emergencyContactName = state.emExaminerDetail.emergencyContactName;
        state.examinerDetailForm.ukvi = state.emExaminerDetail.ukvi;

        state.emExaminerDetail.examinerRoleEnums?.forEach(role => {
          if (role === examinerRoleEnum.SecondMarking) {
            state.examinerRoleChecked.push('2nd Marking');
          }
          else if (role !== "") {
            state.examinerRoleChecked.push(role);
          }
        })
        state.examinerDetailPageTitle = "Examiner: " + state.emExaminerDetail.firstName + " " + state.emExaminerDetail.lastName + "(" + state.emExaminerDetail.no + ")";
        if (examinerDetail.emmExaminer) {
          await CheckExaminerDataUpdates();
        }

      } finally {
        saveExamienrDetailLoading.value = false;
      }
    }

    async function CheckExaminerDataUpdates() {
      if (state.emmExaminerDetail.old_FirstName != state.emmExaminerDetail.firstName
        || state.emmExaminerDetail.old_LastName != state.emmExaminerDetail.lastName
        || moment(state.emmExaminerDetail.old_BirthDate).format('DD/MM/YYYY') != moment(state.emmExaminerDetail.birthDate).format('DD/MM/YYYY')
        || state.emmExaminerDetail.old_Mobile != state.emmExaminerDetail.mobile
        || state.emmExaminerDetail.old_TelephoneNumber != state.emmExaminerDetail.telephoneNumber
        || state.emmExaminerDetail.old_EmailAddress != state.emmExaminerDetail.emailAddress
        || state.emmExaminerDetail.old_AlternativeEmailAddress != state.emmExaminerDetail.alternativeEmailAddress
        || state.emmExaminerDetail.old_Region != state.emmExaminerDetail.region
        || state.emmExaminerDetail.old_CityBased != state.emmExaminerDetail.cityBased
        || state.emmExaminerDetail.old_Nationality != state.emmExaminerDetail.nationality
        || state.emmExaminerDetail.old_UKVI != state.emmExaminerDetail.ukvi
        || state.emmExaminerDetail.old_No != state.emmExaminerDetail.no
      ) {
        confirmUpdatesBtnShow.value = true;
      }
      else {
        confirmUpdatesBtnShow.value = false;
      }
    }

    onMounted(async () => {
      await getRegionList()
      await getExaminerDetail()
      await getProvinceList()
      await getcityList();
    });


    async function getRegionList() {
      state.regionList = await _Client.regionClient.getAll(true)
    }

    const filterOptionRegion = (input: string, option: any) => {
      return state.regionList.filter(x => x.id === option.props.value)[0].name!.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    async function getProvinceList() {
      state.provinceList = await _Client.provinceClient.getAll()
    }

    async function handleProvinceChange() {
      state.examinerDetailForm.cityBasedId = undefined;
      await getcityList();
    }

    async function getcityList() {
      if (state.emExaminerDetail.provinceId) {
        state.provinceCityList = await _Client.cityClient.getProvinceCity(state.emExaminerDetail.provinceId);
      }
      else {
        state.provinceCityList = new Array<GetProvinceCityRep>();
      }
    }

    const filterOptionCityBased = (input: string, option: any) => {
      return state.provinceCityList.filter(x => x.id === option.props.value)[0].name_EN!.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const filterOptionProvince = (input: string, option: any) => {
      return state.provinceList.filter(x => x.id === option.props.value)[0].name!.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    function provinceTooltip(provinceId: any) {
      let province = state.provinceList.find(x => x.id == provinceId);
      if (province != undefined) {
        return province.name;
      }
      return undefined;
    }

    function cityBasedTooltip(cityId: any) {
      let city = state.provinceCityList.find(x => x.id == cityId);
      if (city != undefined) {
        return city.name_EN;
      }
      return undefined;
    }

    function handleStr(value: any, sliceNumber: number) {
      if (!value) return '';
      if (value.length > sliceNumber) {
        return value.slice(0, sliceNumber) + '...'
      }
      return value;
    }

    function showUpdatePhoto() {
      (<any>uploadPhotoRef.value).click();
    }

    function showUpdatePassport() {
      (<any>uploadPassportRef.value).click();
    }

    async function beforeUploadPhoto(file: UploadProps['fileList'][number]) {
      try {
        uploadPhotoLoading.value = true;
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const fileFormatArr = ['JPG', 'PNG', 'JPEG', 'PSD', 'TIFF', 'AI', 'EPS', 'SVG', 'GIF', 'RAW', 'PDF', 'BMP', 'WEBP', 'JFIF', 'BMP'];
        const isLt2M = file.size / 1024 / 1024 < 10
        if (fileFormatArr.indexOf(testmsg.toUpperCase()) == -1) {
          message.warning('上传文件只能是 JPG, PNG, JPEG, PSD, TIFF, AI, EPS, SVG, GIF, RAW, PDF, BMP, WEBP, JFIF, BMP 格式!')
          uploadPhotoLoading.value = false;
          return Promise.reject()
        }

        if (!isLt2M) {
          message.warning('上传文件大小不能超过 10MB!')
          uploadPhotoLoading.value = false;
          return Promise.reject()
        }

        var uploadResult = await _Client.uploadAttachmentClient.uploadFile(<any>{ data: file, fileName: file.name }, BusinessAttachmentTypeEnum.ExaminerPhoto);
        state.emExaminerDetail.photoAttachment = AttachmentResponse.fromJS({ id: uploadResult.id, fileUrl: uploadResult.fileUrl })

      }
      finally {
        uploadPhotoLoading.value = false;
      }

      return Promise.reject()
    };

    async function beforeUploadPassportCopy(file: UploadProps['fileList'][number]) {
      try {
        uploadPassportLoading.value = true;
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const fileFormatArr = ['JPG', 'PNG', 'JPEG', 'PSD', 'TIFF', 'AI', 'EPS', 'SVG', 'GIF', 'RAW', 'PDF', 'BMP', 'WEBP', 'JFIF', 'BMP'];
        const isLt2M = file.size / 1024 / 1024 < 10
        if (fileFormatArr.indexOf(testmsg.toUpperCase()) == -1) {
          message.warning('上传文件只能是 JPG, PNG, JPEG, PSD, TIFF, AI, EPS, SVG, GIF, RAW, PDF, BMP, WEBP, JFIF, BMP 格式!')
          uploadPassportLoading.value = false;
          return Promise.reject()
        }

        if (!isLt2M) {
          message.warning('上传文件大小不能超过 10MB!')
          uploadPassportLoading.value = false;
          return Promise.reject()
        }

        var uploadResult = await _Client.uploadAttachmentClient.uploadFile(<any>{ data: file, fileName: file.name }, BusinessAttachmentTypeEnum.ExaminerPassportCopy);
        state.emExaminerDetail.passportCopyAttachment = new AttachmentResponse(<AttachmentResponse>{ id: uploadResult.id, fileUrl: uploadResult.fileUrl })
      }
      finally {
        uploadPassportLoading.value = false;
      }

      return Promise.reject()
    };

    async function removePhotoAttachment() {
      state.emExaminerDetail.photoAttachment = undefined;
    };

    async function removePassportCopyAttachment() {
      state.emExaminerDetail.passportCopyAttachment = undefined;
    };

    async function roleChange(checkedRoles: any[]) {
      if (!state.examinerRoleChecked || state.examinerRoleChecked == null || state.examinerRoleChecked == undefined || state.examinerRoleChecked.length == 0) {
        state.examinerRoleChecked = checkedRoles;
        if (checkedRoles.findIndex(role => role == ExaminerRoleEnum.VCS || role == ExaminerRoleEnum.ICS) >= 0
          && checkedRoles.findIndex(role => role == ExaminerRoleEnum.Speaking) === -1) {
          state.examinerRoleChecked?.push(examinerRoleEnum.Speaking);
        }
      }
      else {
        // 说明是取消选项
        if (state.examinerRoleChecked.length > checkedRoles.length) {
          if (checkedRoles.findIndex(role => role == ExaminerRoleEnum.Speaking) === -1) {
            if (checkedRoles.findIndex(role => role == ExaminerRoleEnum.VCS) === 0) {
              checkedRoles.splice(checkedRoles.indexOf(ExaminerRoleEnum.VCS), 1);
            }

            if (checkedRoles.findIndex(role => role == ExaminerRoleEnum.ICS) >= 0) {
              checkedRoles.splice(checkedRoles.indexOf(ExaminerRoleEnum.ICS), 1);
            }
          }
        }
        else {
          // 说明是新增选项
          if (checkedRoles.findIndex(role => role == ExaminerRoleEnum.VCS) === 0) {
            if (checkedRoles.findIndex(role => role == ExaminerRoleEnum.Speaking) === -1) {
              checkedRoles.push(examinerRoleEnum.Speaking);
            }
          }

          if (checkedRoles.findIndex(role => role == ExaminerRoleEnum.ICS) >= 0) {
            if (checkedRoles.findIndex(role => role == ExaminerRoleEnum.Speaking) === -1) {
              checkedRoles.push(examinerRoleEnum.Speaking);
            }
          }
        }
      }


      state.examinerRoleChecked = checkedRoles;

      if (state.examinerRoleChecked.length == 0) {
        message.warning('Please select at least one role!')
        return;
      }
    }

    async function SaveExaminer() {
      validate().then(res => {
        if (state.examinerRoleChecked == undefined || state.examinerRoleChecked.length == 0) {
          message.warning('Please select at least one role!')
          return;
        }
        saveExamienrDetailLoading.value = true;
        if (confirmUpdatesBtnShow.value == true && confirmUpdatesOrNot.value == false && state.emExaminerDetail.examinerSystemStatus === examinerSystemStatusEnum.Change) {
          Modal.confirm({
            content: 'You have not confirmed updates in Profile, by clicking save at this time will only save contents originated from EM. Are you sure you want to save without applying EMM updates?',
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk: ConfirmSaveExaminer,
            onCancel: CancelConfirmUpdate
          });
        } else {
          ConfirmSaveExaminer()
        }
      }).catch(err => {

      })
    }

    async function ConfirmSaveExaminer() {
      try {
        if (leftEffectiveDayjsDate.value != null) {
          state.emExaminerDetail.leftEffectiveDate = leftEffectiveDayjsDate.value.toDate();
        }
        else
        {
          state.emExaminerDetail.leftEffectiveDate = undefined;
        }
        var command = new ModifyExaminerCmd(<ModifyExaminerCmd><unknown>state.emExaminerDetail);
        command.emmRegionName = state.emmExaminerDetail.region;
        command.emmCityName = state.emmExaminerDetail.cityBased;
        command.photoAttachment = state.emExaminerDetail.photoAttachment;
        command.passportCopyAttachment = state.emExaminerDetail.passportCopyAttachment;
        if (state.examinerRoleChecked?.length > 0) {
          state.emExaminerDetail.examinerRoleEnums = new Array<ExaminerRoleEnum>();
          state.examinerRoleChecked?.forEach(role => {
            if (role === '2nd Marking') {
              state.emExaminerDetail.examinerRoleEnums!.push(examinerRoleEnum.SecondMarking);
            }
            else {
              state.emExaminerDetail.examinerRoleEnums!.push(<ExaminerRoleEnum>role);
            }
          })
        }

        command.examinerRoles = state.emExaminerDetail.examinerRoleEnums;
        command.gender = state.examinerDetailForm.gender;
        command.passPortNumber = state.examinerDetailForm.passPortNumber;
        command.workStatus = state.examinerDetailForm.workStatus!;
        command.regionId = state.examinerDetailForm.regionId!;
        command.cityId = state.examinerDetailForm.cityBasedId!;
        command.emergencyContactEmail = state.examinerDetailForm.emergencyContactEmail;
        command.emergencyContactMobileNumber = state.examinerDetailForm.emergencyContactMobileNumber;
        command.middleName = state.examinerDetailForm.middleName;
        command.emergencyContactName = state.examinerDetailForm.emergencyContactName;
        command.mobile = state.examinerDetailForm.mobile;
        command.telephoneNumber = state.examinerDetailForm.telephoneNumber;

        await _Client.examinerClient.modify(command)
        back();
        message.success('Update Success')
      } finally {
        saveExamienrDetailLoading.value = false;
      }
    }

    function CancelConfirmUpdate() {
      saveExamienrDetailLoading.value = false;
    }

    async function confirmUpdate() {
      confirmUpdateBtnLoading.value = true;
      try {
        await _Client.examinerClient.checkConfirmUpdate(examinerId);
      }
      finally {
      }

      state.emmExaminerDetail.old_FirstName = state.emmExaminerDetail.firstName;
      state.emmExaminerDetail.old_LastName = state.emmExaminerDetail.lastName;
      state.emmExaminerDetail.old_BirthDate = state.emmExaminerDetail.birthDate;
      state.emmExaminerDetail.old_No = state.emmExaminerDetail.no;
      state.emmExaminerDetail.old_Mobile = state.emmExaminerDetail.mobile;
      state.emmExaminerDetail.old_TelephoneNumber = state.emmExaminerDetail.telephoneNumber;
      state.emmExaminerDetail.old_EmailAddress = state.emmExaminerDetail.emailAddress;
      state.emmExaminerDetail.old_AlternativeEmailAddress = state.emmExaminerDetail.alternativeEmailAddress;
      state.emmExaminerDetail.old_Region = state.emmExaminerDetail.region;
      state.emmExaminerDetail.old_CityBased = state.emmExaminerDetail.cityBased;
      state.emmExaminerDetail.old_Nationality = state.emmExaminerDetail.nationality;
      state.emmExaminerDetail.old_UKVI = state.emmExaminerDetail.ukvi;

      state.emExaminerDetail.firstName = state.emmExaminerDetail.firstName;
      state.emExaminerDetail.lastName = state.emmExaminerDetail.lastName;
      state.emExaminerDetail.birthDate = state.emmExaminerDetail.birthDate;
      state.emExaminerDetail.mobile = state.emmExaminerDetail.mobile;
      state.emExaminerDetail.telephoneNumber = state.emmExaminerDetail.telephoneNumber;
      state.emExaminerDetail.emailAddress = state.emmExaminerDetail.emailAddress;
      state.emExaminerDetail.alternativeEmailAddress = state.emmExaminerDetail.alternativeEmailAddress;
      state.emExaminerDetail.ukvi = state.emmExaminerDetail.ukvi;
      state.emExaminerDetail.passPortNumber = state.examinerDetailForm.passPortNumber;
      state.emExaminerDetail.workStatus = state.examinerDetailForm.workStatus;
      state.emExaminerDetail.regionId = state.examinerDetailForm.regionId;
      state.emExaminerDetail.cityBasedId = state.examinerDetailForm.cityBasedId;
      state.emExaminerDetail.no = state.emmExaminerDetail.no;

      state.emExaminerDetail.emergencyContactEmail = state.examinerDetailForm.emergencyContactEmail;
      state.emExaminerDetail.emergencyContactMobileNumber = state.examinerDetailForm.emergencyContactMobileNumber;
      state.emExaminerDetail.middleName = state.examinerDetailForm.middleName;
      state.emExaminerDetail.emergencyContactName = state.examinerDetailForm.emergencyContactName;


      state.emExaminerDetail.nationality = state.emmExaminerDetail.nationality;
      state.emExaminerDetail.examinerSystemStatus = SystemStatusEnum.Valid;
      confirmUpdatesBtnShow.value = false;
      confirmUpdatesOrNot.value = true;
      confirmUpdateBtnLoading.value = false;
    }


    function closeModal() {
      Modal.confirm({
        content: "Any changes will not be saved, are you sure you want to close this window?",
        okText: "Yes",
        cancelText: "No",
        onOk: back
      });
    }

    return {
      ...toRefs(state),
      getExaminerDetail,
      timeMoment,
      workStatusEnum,
      examinerRoleEnum,
      back,
      examinerRoleEnums,
      uploadPhotoRef,
      uploadPassportRef,
      beforeUploadPhoto,
      removePhotoAttachment,
      removePassportCopyAttachment,
      beforeUploadPassportCopy,
      showUpdatePhoto,
      showUpdatePassport,
      roleChange,
      examinerRoles,
      SaveExaminer,
      saveExamienrDetailLoading,
      workStatuss,
      genderEnums,
      validate,
      validateInfos,
      resetFields,
      confirmUpdatesBtnShow,
      confirmUpdate,
      uploadPhotoLoading,
      uploadPassportLoading,
      examinerProfileStatusEnum,
      examinerMonitorStatusEnum,
      examinerLastSessionStatusEnum,
      examinerCertificationStatusEnum,
      closeModal,
      examinerSystemStatusEnum,
      handleStr,
      filterOptionRegion,
      handleProvinceChange,
      filterOptionProvince,
      filterOptionCityBased,
      provinceTooltip,
      cityBasedTooltip,
      confirmUpdateBtnLoading,
      leftEffectiveDayjsDate,
      isDisabledEditProfileStatus
    };
  },
});